import React from "react";
import { Curve, FlexSection, ImageSection, FillMargin } from "./layout";
import { H2, Text } from "@lewisblackwood/component-library";
import { CustomerLogo } from "../styledComponents/Customers";

// Images
import MonzoLogo from "../images/logo-monzo.svg";
import CarwowLogo from "../images/logo-carwow.svg";
import CytoraLogo from "../images/logo-cytora.svg";
import GoCardlessLogo from "../images/logo-gocardless.svg";
import OnfidoLogo from "../images/logo-onfido.svg";

const Customers = () => (
  <>
    <Curve />
    <FillMargin />
    <FlexSection colour="grey" column="2">
      <H2 size="xlarge" sizeMobile="large" mb="xlarge" center>
        Already on board
      </H2>
      <ImageSection mb="medium" wrap>
        <CustomerLogo src={MonzoLogo} alt="Monzo" />
        <CustomerLogo src={GoCardlessLogo} alt="GoCardless" />
        <CustomerLogo src={CarwowLogo} alt="carwow" />
        <CustomerLogo src={CytoraLogo} alt="Cytora" />
        <CustomerLogo src={OnfidoLogo} alt="Onfido" />
      </ImageSection>
      <Text size="xlarge" sizeMobile="large" center>
        and more fast-growing companies.
      </Text>
    </FlexSection>
    <FillMargin right />
    <Curve flipped mb="xxlarge" />
  </>
);

export default Customers;

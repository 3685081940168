import React, { Fragment } from "react";
import PricingCalculator from "../pricing/PricingCalculator";

// styled-components
import { withTheme } from "styled-components";
import { Tabs, Text } from "@lewisblackwood/component-library";
import {
  ContentSection,
  Curve,
  FillMargin,
  FlexSection,
  GridSection
} from "../../components/layout";
import { FlexItem } from "../../components/layout";

// images
import TrendIcon from "../../images/icon-trending-up.svg";
import { PricePlanCard } from "../../styledComponents/pricing/PricingFeatures";

class PricingFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyContent: this.prices[1],
      hireRate: 84
    };
  }

  prices = [
    {
      symbol: "€",
      startupPrice: "70",
      scalingPrice: "60",
      code: "EUR"
    },
    {
      symbol: "£",
      startupPrice: "60",
      scalingPrice: "50",
      code: "GBP"
    },
    {
      symbol: "$",
      startupPrice: "80",
      scalingPrice: "65",
      code: "USD"
    }
  ];

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("currency");
    const hires = query.get("hires");
    const codeIndex = this.prices.findIndex(elem => elem.code === code);
    this.setState({
      currencyContent: this.prices[codeIndex === -1 ? 1 : codeIndex]
    });
    this.setState({ hireRate: hires || 84 });
  }

  currencyChange = index =>
    this.setState({ currencyContent: this.prices[index] });

  render() {
    const { currencyContent, hireRate } = this.state;
    const isEUR = currencyContent.code === "EUR";
    const isGBP = currencyContent.code === "GBP";
    const isUSD = currencyContent.code === "USD";

    return (
      <Fragment>
        <ContentSection column="1 / 3" style={{ width: "100vw" }}>
          <Tabs
            tabs={[
              { name: "EUR", active: isEUR },
              { name: "GBP", active: isGBP },
              { name: "USD", active: isUSD }
            ]}
            width="190px"
            center
            onChangeHandler={this.currencyChange}
          />
          <GridSection
            rows="120px 80px auto 80px"
            columns="minmax(32px, 1fr) minmax(auto, 1300px) minmax(32px, 1fr)"
            columnsMobile="16px calc(100vw - 32px) 16px"
            style={{ width: "100vw" }}
          >
            <FlexSection
              row="1 / 4"
              column="2"
              flexDirection="row"
              justifyContent="center"
              wrap
              style={{ margin: "-8px" }}
            >
              <FlexItem>
                <PricePlanCard
                  plan="Start-up"
                  hireRate="Hiring under 4 people&#47;month"
                  commitment="With no monthly commitment, pay for what you&nbsp;use"
                  buttonLink="mailto:hello@personably.co"
                >
                  <Text
                    colour="pink"
                    bold
                    center
                    mb="none"
                    css={{ fontSize: 48, marginLeft: 58 }}
                  >
                    <sup style={{ fontSize: 22 }}>{currencyContent.symbol}</sup>
                    {currencyContent.startupPrice}
                    <sup
                      style={{
                        fontSize: 16,
                        fontWeight: 200,
                        color: this.props.theme.colours.black.light,
                        marginLeft: 8
                      }}
                    >
                      per hire
                    </sup>
                  </Text>
                </PricePlanCard>
              </FlexItem>

              <FlexItem animateIn="fadeAndRiseUpSlow">
                <PricePlanCard
                  plan="Scale-up"
                  hireRate="Hiring up to 15 people&#47;month"
                  commitment="With a monthly minimum based on your hiring&nbsp;rate"
                  primary
                  buttonLink="mailto:hello@personably.co"
                >
                  <Text
                    colour="pink"
                    bold
                    center
                    mb="none"
                    css={{ fontSize: 48, marginLeft: 48 }}
                  >
                    <sup style={{ fontSize: 22 }}>{currencyContent.symbol}</sup>
                    {currencyContent.scalingPrice}
                    <sup
                      style={{
                        fontSize: 16,
                        fontWeight: 200,
                        color: this.props.theme.colours.black.light,
                        marginLeft: 8
                      }}
                    >
                      per hire
                    </sup>
                  </Text>
                </PricePlanCard>
              </FlexItem>

              <FlexItem animateIn="fadeAndRiseUpSlower">
                <PricePlanCard
                  plan="Enterprise &amp; High-growth"
                  hireRate="Hiring over 15 people&#47;month"
                  commitment="With a monthly minimum based on your hiring&nbsp;rate"
                  buttonLink="mailto:hello@personably.co"
                >
                  <img
                    src={TrendIcon}
                    style={{
                      display: "block",
                      height: 40,
                      width: "auto",
                      margin: "17px auto 14px auto"
                    }}
                    alt=""
                  />
                </PricePlanCard>
              </FlexItem>
            </FlexSection>

            <Curve colour="pink" row="2" column="1 / 4" alignSelf="end" />
            <FillMargin colour="pink" row="3" column="1 / 4" />
            <Curve
              flipped
              colour="pink"
              row="4"
              column="1 / 4"
              alignSelf="start"
            />
          </GridSection>
        </ContentSection>

        <PricingCalculator
          currencyContent={currencyContent}
          numOfHires={hireRate}
          hireChange={e => {
            if (e.target.value.length <= 4) {
              this.setState({
                hireRate: Number(e.target.value.replace(/[^0-9]+/, "")) || ""
              });
            }
          }}
        />
      </Fragment>
    );
  }
}

export default withTheme(PricingFeatures);

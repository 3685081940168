import styled from "styled-components";
import media from "./Media";
import { fadeIn } from "./Common";

export const CustomerLogosWrapper = styled.div`
  margin-top: 72px;
  text-align: center;
  ${fadeIn}

  ${media.medium`
    margin-top: 64px;
  `};
`;

export const CustomerLogo = styled.img`
  margin: ${props =>
    `0 ${props.theme.spacing.medium}
    ${props.theme.spacing.medium} ${props.theme.spacing.medium}`};
`;

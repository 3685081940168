import React from "react";

// styled-components
import styled, { withTheme } from "styled-components";
import {
  Button,
  Card,
  FormInput,
  H2,
  LinkButton,
  Text
} from "@lewisblackwood/component-library";
import { Illustration } from "../../styledComponents/Common";
import { ContentSection, FlexSection, FlexItem } from "../../components/layout";

// images
import Abacus from "../../images/abacus.svg";

const CalculatorInput = styled(FormInput)`
  input {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
  }
`;

const PricingCalculator = props => {
  const hireRate = props.numOfHires;

  const hiresPerMonth = () => {
    const total = hireRate / 12;
    const totalRounded = Math.ceil(total);
    return totalRounded;
  };

  const payPerMonth = () => {
    const hires = hiresPerMonth();
    let multiplier;

    if (hireRate <= 48) {
      multiplier = props.currencyContent.startupPrice;
    } else if (hireRate > 48) {
      multiplier = props.currencyContent.scalingPrice;
    }

    const total = hires * multiplier;
    return total;
  };

  const minCommitment = () => {
    if (props.currencyContent.symbol === "£") {
      return "200";
    } else if (props.currencyContent.symbol === "$") {
      return "260";
    } else if (props.currencyContent.symbol === "€") {
      return "240";
    }
  };

  return (
    <ContentSection>
      <FlexSection
        style={{ maxWidth: 680, margin: "0 auto" }}
        as="form"
        id="calculator"
      >
        <Illustration src={Abacus} mb="xsmall" />
        <H2 center size="xlarge" sizeMobile="xlarge" mb="xlarge">
          Price calculator
        </H2>
        <Text
          as="label"
          bold
          center
          size="xlarge"
          colour="black"
          mb="xsmall"
          id="predictedHires"
        >
          Predicted hires over the next 12 months
        </Text>
        <CalculatorInput
          value={hireRate}
          handleChange={props.hireChange}
          width="120px"
          type="number"
          htmlAttrs={{ htmlFor: "predictedHires" }}
          mb="xlarge"
        />
        <Text center size="xlarge" colour="black" bold mb="large">
          We recommend the{" "}
          <span
            style={{
              color: props.theme.colours.pink.default
            }}
          >
            {hireRate <= 48 && "Start-up"}
            {hireRate > 48 && hireRate <= 180 && "Scale-up"}
            {hireRate > 180 && "Enterprise"}
          </span>{" "}
          plan
        </Text>

        <FlexSection
          wrap
          justifyContent="center"
          as="output"
          flexDirection="row"
          mb="medium"
        >
          <FlexItem>
            <Card width="100%">
              <Text size="xlarge" sizeMobile="large" center>
                You'll hire around
              </Text>
              <Text center bold colour="pink" style={{ fontSize: 36 }}>
                {hiresPerMonth()}
              </Text>
              <Text size="xlarge" sizeMobile="large" center>
                people per month
              </Text>
            </Card>
          </FlexItem>

          <FlexItem>
            <Card width="100%">
              <Text size="xlarge" sizeMobile="large" center>
                You'll pay around
              </Text>
              <Text center bold colour="pink" style={{ fontSize: 36 }}>
                {props.currencyContent.symbol}
                {payPerMonth()}
              </Text>
              <Text size="xlarge" sizeMobile="large" center>
                per month
              </Text>
            </Card>
          </FlexItem>
        </FlexSection>
        <Text center mb="xlarge">
          This plan{" "}
          {hireRate <= 48 && "has no minimum commitment each\xa0month"}
          {hireRate > 48 &&
            hireRate <= 180 &&
            `includes a minimum commitment of ${
              props.currencyContent.symbol
            }${minCommitment()}\xa0per\xa0month`}
          {hireRate > 180 &&
            "includes a custom minimum commitment each\xa0month"}
        </Text>
      </FlexSection>
    </ContentSection>
  );
};

export default withTheme(PricingCalculator);

import React, { Component, Fragment } from "react";
import ReactModal from "react-modal";

// styled-components
import {
  Card,
  H2,
  Text,
  TextLink,
  FlexContainer,
  modalStyles
} from "@lewisblackwood/component-library";
import { ContentSection, FlexSection } from "../layout";
import {
  Illustration,
  PlayButton,
  ProductVideo,
  FeatureCard
} from "../../styledComponents/Common";

// images
import Calendar from "../../images/feature-calendar.svg";
import Email from "../../images/feature-email.svg";
import Clipboard from "../../images/feature-clipboard.svg";
import PlayIcon from "../../images/icon-play.svg";

class ProductFeatures extends Component {
  state = { isModalOpen: false };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <Fragment>
        <ContentSection css={{ marginTop: 40 }}>
          <H2 center size="xlarge" sizeMobile="xlarge" mb="large">
            With Personably you&nbsp;can&hellip;
          </H2>

          <FlexSection
            flexDirection="row"
            wrap
            justifyContent="center"
            css={{ marginBottom: 20 }}
          >
            <FeatureCard>
              <Illustration src={Email} row="1 / 2" column="3" />
              <Card
                css={{
                  gridRow: "2 / 6",
                  gridColumn: "1 / 6",
                  justifyContent: "flex-start"
                }}
              >
                <Text
                  size="xlarge"
                  sizeMobile="xlarge"
                  colour="black"
                  bold
                  center
                  mb="xxsmall"
                  css={{ marginTop: 24 }}
                >
                  Keep in touch with your hires effortlessly
                </Text>
                <Text center size="large" sizeMobile="large" mb="none">
                  Make them feel part of the team in that gap between accepting
                  their offer and their first day.
                </Text>
              </Card>
            </FeatureCard>

            <FeatureCard animateIn="fadeAndRiseUpSlow">
              <Illustration src={Clipboard} row="1 / 2" column="3" />
              <Card
                css={{
                  gridRow: "2 / 6",
                  gridColumn: "1 / 6",
                  justifyContent: "flex-start"
                }}
              >
                <Text
                  size="xlarge"
                  sizeMobile="xlarge"
                  colour="black"
                  bold
                  center
                  mb="xxsmall"
                  css={{ marginTop: 24 }}
                >
                  Have everything set up for their first&nbsp;day
                </Text>
                <Text center size="large" sizeMobile="large" mb="xsmall">
                  Automatically add hires to the tools they need, ready for
                  their&nbsp;arrival.
                </Text>
                <Text center size="large" sizeMobile="large" mb="none">
                  Keep your team in the loop.
                </Text>
              </Card>
            </FeatureCard>

            <FeatureCard animateIn="fadeAndRiseUpSlower">
              <Illustration src={Calendar} row="1 / 2" column="3" />
              <Card
                css={{
                  gridRow: "2 / 6",
                  gridColumn: "1 / 6",
                  justifyContent: "flex-start"
                }}
              >
                <Text
                  size="xlarge"
                  sizeMobile="xlarge"
                  colour="black"
                  bold
                  center
                  mb="xxsmall"
                  css={{ marginTop: 24 }}
                >
                  Collaborate on training up new hires
                </Text>
                <Text center size="large" sizeMobile="large" mb="none">
                  Then automatically schedule sessions into calendars at the
                  right time for each hire.
                </Text>
              </Card>
            </FeatureCard>
          </FlexSection>

          <FlexContainer justifyContent="center" mb="xlarge">
            <PlayButton onClick={this.openModal}>
              <div>
                <img src={PlayIcon} alt="" />
              </div>
              <TextLink size="xlarge" sizeMobile="large" bold colour="purple">
                Watch our product video
              </TextLink>
            </PlayButton>
          </FlexContainer>
        </ContentSection>

        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          style={modalStyles({ width: "auto", margin: "0 16px" })}
          ariaHideApp={false}
        >
          <ProductVideo />
        </ReactModal>
      </Fragment>
    );
  }
}

export default ProductFeatures;

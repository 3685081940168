import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Customers from "../components/Customers";
import Hero from "../components/pricing/Hero";
import PricingFeatures from "../components/pricing/PricingFeatures";
import ProductFeatures from "../components/pricing/ProductFeatures";

const Pricing = () => (
  <Layout>
    <Helmet>
      <title>Pricing</title>
      <meta
        name="description"
        content="A flexible pricing system to help teams start delivering an outstanding onboarding experience"
      />
    </Helmet>
    <Hero />
    <PricingFeatures />
    <ProductFeatures />
    <Customers />
  </Layout>
);

export default Pricing;

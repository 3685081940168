import React from "react";
import styled from "styled-components";
import media from "../Media";

import { Button, LinkButton, Card, Text } from "@lewisblackwood/component-library";

const StyledPricePlanCard = styled(Card)`
  width: 100%;
  height: 370px;

  ${media.small`
    height: auto;
  `}
`;

export const PricePlanCard = props => (
  <StyledPricePlanCard>
    <Text
      bold
      center
      colour="black"
      size="xxlarge"
      sizeMobile="xlarge"
      mb="none"
    >
      {props.plan}
    </Text>
    <Text center size="xlarge">
      {props.hireRate}
    </Text>
    {props.children}
    <Text colour="grey" center mb="medium" size="default">
      {props.commitment}
    </Text>

    {props.button && <LinkButton
      href={props.buttonLink}
      outline={!props.primary}
      action
      height="48px"
    >
      {props.button}
    </LinkButton>}
  </StyledPricePlanCard>
);
